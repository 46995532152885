<template>
    <div>
        <h5 class="mb-3 bold orange-text">Please enter and rate your skills</h5>
        <!-- <hr style="margin-bottom: 14px"> -->
        <div class="margin-bottom selection" :key="userSkillList.index" v-for="(userSkillList, index) in userSkill">
            <div class="skill-border" v-bind:class="['orange-border', (userSkillList.rating == 0) ? 'orange-border' : 'green-border']">
                <div class="skill-container">
                    <el-row>
                        <el-col>
                            <el-row type="flex">
                                <el-col>
                                    <div class="rate-wrap">
                                        <h5 class="head-skill">{{ userSkillList.skill_name | ucFirst }}</h5>
                                    </div>
                                </el-col>
                                <el-col>
                                    <div class="delete-skill">
                                        <span @click="remove(userSkillList, index)"><i class="el-icon-delete" style="cursor:pointer"></i></span>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col>
                            <el-row>
                                <el-col>
                                    <ul class="rating">
                                        <li v-if="userSkillList.rating >= 5"
                                            class="selected" @click="$emit('updateSkillRating', $event, userSkillList)" :value=5 :id="userSkillList.skill_id"></li>
                                        <li v-else class="unselected" @click="$emit('updateSkillRating', $event, userSkillList)" :value=5 :id="userSkillList.skill_id"></li>

                                        <li v-if="userSkillList.rating >= 4" class="selected" @click="$emit('updateSkillRating', $event, userSkillList)" :value=4 :id="userSkillList.skill_id"></li>
                                        <li v-else class="unselected" @click="$emit('updateSkillRating', $event, userSkillList)" :value=4 :id="userSkillList.skill_id"></li>

                                        <li v-if="userSkillList.rating >= 3" class="selected" @click="$emit('updateSkillRating', $event, userSkillList)" :value=3 :id="userSkillList.skill_id"></li>
                                        <li v-else class="unselected" @click="$emit('updateSkillRating', $event, userSkillList)" :value=3 :id="userSkillList.skill_id"></li>

                                        <li v-if="userSkillList.rating >= 2" class="selected" @click="$emit('updateSkillRating', $event, userSkillList)" :value=2 :id="userSkillList.skill_id"></li>
                                        <li v-else class="unselected" @click="$emit('updateSkillRating', $event, userSkillList)" :value=2 :id="userSkillList.skill_id"></li>

                                        <li v-if="userSkillList.rating >= 1" class="selected" @click="$emit('updateSkillRating', $event, userSkillList)" :value=1 :id="userSkillList.skill_id"></li>
                                        <li v-else class="unselected" @click="$emit('updateSkillRating', $event, userSkillList)" :value=1 :id="userSkillList.skill_id"></li>
                                    </ul>
                                </el-col>
                                <el-col>
                                    <div v-if="userSkillList.rating == 0" class="rate-star-error">
                                        <div class="rate-star-error">Please rate this skill.</div>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
        <div v-if="skillsLimitError" class="error-text">You should have at least one skill and the maximum is 15.</div>
        <div v-if="duplicateSkillError"
             class="error-text">Hmmm, it looks like
                                it's already on your
                                list.</div>
    </div>
</template>
<script>

    export default {
        name: "Skills",
        props: {
            userSkill: {
                //type: Object
            },
            incompleteSkillsError: {},
            skillsLimitError: {
                type: Boolean,
            },
            duplicateSkillError: {
                type: Boolean
            }
        },
        methods: {
            remove(skill, index) {
                this.$emit('removeSkill', {skill,index})
            }
        },
        filters: {
            ucFirst: function (value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            }
        },


    };
</script>
<style scoped>
    .selection div {
        font-size: 14px;
        color: #606266;

        -webkit-box-sizing: border-box;
        box-sizing: border-box;

    }
    .selection p {
        color: #8F8F8F;
        font-weight: bold;
    }
    .selection ul > li {
        display: inline-block;
        zoom: 1;
        display: inline;
        margin: 5px;
    }
    .margin-bottom {
        margin-bottom: 10px;
    }
    .error-text {
        color:red;
        font-size: 12px;
    }
    .rating {
        unicode-bidi: bidi-override;
        direction: rtl;
        margin-bottom: 0;
    }
    .rating > li {
        display: inline-block;
        position: relative;
        width: 1.3em;
        font-size: 1em;
        cursor: pointer;
    }
    .rating > li:before {
        content: "\2606";
        -webkit-text-stroke: 0.5px #8a8a8a;
    }
    .rating > li.selected:before,
    .rating > li.selected ~ li:before {
        content: "\2605";
        color: #F2B84B;
    }
    .rating > li:hover:before,
    .rating > li:hover ~ li:before {
        content: "\2605";
        color: #F2B84B;
    }

    .head-skill {
        color: #000000;
        font-weight: bold;
        font-size: 13px !important;
    }
    .grey {
        color: #A7A7A7;
    }
    .skill-border {
        border: 1px solid #ccc;

        display: block;
        padding: 4px 8px;
        border-radius: 4px;
        width: 100%;
    }
    .orange-border {
        border-left: 5px solid #E87722;
    }
    .green-border {
        border-left: 5px solid green;
    }
    .skill-container {
        margin: 10px;
    }
    .selection .error-text {
        color:red;
    }
    .rate-star-error {
        color: red !important;
        font-size: 12px !important;
        margin-left: 65px;
        margin-top: -18px;
    }
    .welcome-text {
        font-size: 12px !important;
    }
    .delete-skill{
        text-align: right;
        margin-top: -10px;
        margin-right: -10px;
    }
</style>
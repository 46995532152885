<template>
  <skill
    :skills-limit-error="skillsLimitError"
    :user-skill="skills"
    :duplicate-skill-error="duplicateSkillError"
    @removeSkill="removeSkill"
    @createSkill="createSkill"
    @skillLimitHasError="skillLimitHasError"
    @createSkillProposal="createSkillProposal"
    @updateSkillRating="updateSkillRating"
  />
</template>

<script>
import Skill from '~/components/Skills/Skill'
import SkillMixin from '~/components/Skills/SkillMixin'
import MySkills from '~/models/Skills/Freelancer/MySkills'
import MySkillProposals from '~/models/Skills/Freelancer/MySkillProposals'
import handleError, { prompt } from '~/helpers/handleError'
import { DEFAULT_RATE } from '~/vuex/utils/constant'

export default {
  components: { Skill },

  mixins: [SkillMixin],

  data () {
    return {
      duplicateSkillError: false,
      skills: [],
      skillsLimitError: false,
      loading: false,
    }
  },

  created () {
    this.fetchUserSkills()
  },

  methods: {
    fetchUserSkills () {
      this.loading = true
      MySkills.all().then(response => {
        this.loading = false
        this.skills = response.data.data
        this.fetchProposalSkills()
      }).catch(error => {
        this.loading = false
        handleError(error)
      })
    },

    fetchProposalSkills () {
      this.loading = true
      MySkillProposals.all().then(response => {
        this.loading = false
        this.skills = this.skills.concat(response.data.data)
      }).catch(error => {
        this.loading = false
        handleError(error)
      })
    },

    createSkill (data) {
      this.duplicateSkillError = false;
      // if no data provided, do nothing
      if (! data) {
        return
      }

      // TODO: will add error later
      if (this.hasDuplicates(data).includes(true)) {
        this.duplicateSkillError = true;
        return
      }

      const skillId = typeof data.skill_id === 'undefined' ? data.id : data.skill_id
      const value = (typeof data.value === 'undefined') ? DEFAULT_RATE : data.value

      // update my skill
      MySkills.update(skillId, {
        rating: value,
        skill_order: 1,
      }).then(response => {
        this.skills.push(response.data.data[0])
        this.$store.commit('FREELANCER_UPDATE_SKILLS', response.data.data[0])
      })
        .catch(handleError)
    },

    createSkillProposal (data) {
      // if no data provided, do nothing
      if (! data) {
        return
      }

      // TODO: will add error later
      if (this.hasDuplicates(data).includes(true)) {
        return
      }

      // disallow if max number of skill
      if (this.checkMaxSkill()) {
        return
      }

      // fallback to default if no value given
      const value = (typeof data.value === 'undefined') ? DEFAULT_RATE : data.value

      // create my skill proposal
      MySkillProposals.create({
        skill_name: data.skill_name,
        rating: value,
        skill_order: 1,
      }).then(response => this.skills.push(response.data.data[0]))
        .catch(handleError)
    },

    updateSkillRate (data) {
      // if no data provided, do nothing
      if (! data) {
        return
      }

      const skillId = (typeof data.skill_id === 'undefined') ? data.id : data.skill_id
      const value = (typeof data.value === 'undefined') ? DEFAULT_RATE : data.value

      // update my skill
      MySkills.update(skillId, {
        rating: value,
        skill_order: 1,
      }).then(response => {
        // update the skill in the UI
        const newSkill = response.data.data[0]
        const oldSkill = this.skills.find(skill => skill.skill_id === newSkill.skill_id)
        oldSkill.rating = newSkill.rating
      }).catch(handleError)
    },

    updateProposalRate (skillProposal, target) {
      // update my skill proposal
      MySkillProposals.update(skillProposal.skill_id, {
        skill_name: skillProposal.skill_name,
        rating: target.value,
      }).then(response => {
        // update the skill in the UI
        const newSkill = response.data.data[0]
        const oldSkill = this.skills.find(skill => skill.skill_name === newSkill.skill_name)
        oldSkill.rating = newSkill.rating
      }).catch(handleError)
    },

    deleteSkill (data) {
      let config;
      if (this.$route.path === '/freelancersignup') {
        config = {
          data : {
            from_signup : true
          }
        };
      }
      MySkills.destroy(data.skill.skill_id, config)
        .then((c) => {
          if (c.data.http_code > 210) {
            return prompt(c.data)
          }
          this.skills.splice(data.index, 1)
          this.skillsLimitError = false
          this.$store.commit('FREELANCER_REMOVE_SKILLS', data.skill.skill_id)
        })
        .catch(handleError);
    },

    deleteProposalSkill (data) {
      MySkillProposals.destroy(data.skill.skill_id)
        .then(() => {
          this.skills.splice(data.index, 1)
          this.skillsLimitError = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
    .my-autocomplete {
        li {
            line-height: normal;
            padding: 7px;

            .value {
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .link {
                font-size: 12px;
                color: #b4b4b4;
            }
        }
    }
    .el-collapse,
    .search .el-collapse-item__wrap,
    .search .el-collapse-item__header {
        border: none;
    }
    .search {
        margin-bottom: 5px;
    }
    .search .el-card__body {
        padding: 0 20px;
    }
    .clickHere {
        cursor: pointer;
        color: #409eff;
    }
    .error-text {
        color:red;
        font-size:12px;
    }
</style>
<template>
    <div>
        <skills :incompleteSkillsError="incompleteSkillsError"
                :skillsLimitError="skillsLimitError"
                :duplicateSkillError="duplicateSkillError"
                :userSkill="userSkill"
                @removeSkill="removeSkill"
                @updateSkillRating="updateSkillRating"/>
        <SkillForm
                @skillLimitHasError="skillLimitHasError"
                @createSkillProposal="createSkillProposal"
                @createSkill="createSkill"
                :skillsLimitError="skillsLimitError"
                :userSkill="userSkill"
        />
    </div>
</template>
<script>
import Skills from './Skills'
import SkillForm from './SkillForm'
export default {
    name: 'Skill',
    props: {
        incompleteSkillsError: {
            type: String
        },
        skillsLimitError: {
            type: Boolean
        },
        duplicateSkillError: {
            type: Boolean
        },
        //from getters
        getUserSkill: {
            type: Array
        },
        userSkill: {},
        userSkillList: {},
    },
    components: {
        Skills,
        SkillForm,
    },
    methods: {
        removeSkill(skill) {
            this.$emit('removeSkill', skill)
        },
        updateSkillRating(event, skill) {
            this.$emit('updateSkillRating', event, skill)
        },
        createSkillProposal(proposalSkill) {
            this.$emit('createSkillProposal', proposalSkill)
        },
        createSkill(skill) {
            this.$emit('createSkill', skill)
        },
        skillLimitHasError(event) {
            this.$emit('skillLimitHasError', event)
        }
    }
}
</script>
<style>

</style>